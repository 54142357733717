import { Injectable } from '@angular/core';
import { environment } from '../config/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public apiUrl!: string
  constructor(private http: HttpClient) { }

  login(username: string, password: string) {
    this.apiUrl = environment.apiUrl;
    return this.http.post<any>(this.apiUrl + '/users/authenticate', { username: username.trim().toLowerCase(), password: password })
        .pipe(
            map(user => {
              return user;
            })
    )
}
}